import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCountriesThunk } from "../../redux/actions/misc-actions";
import { getCompaniesThunk } from "../../redux/actions/companies-actions";
import NewCustomerModal from "../../components/customers-owner/new-customer-modal";
import api from "../../api";

const NewCustomerDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const [registerCompanyStatus, setRegisterCompanyStatus] = useState("initial");
  const [company, setCompany] = useState();
  const showBillingAddress = useSelector(
    (state) => state.misc.featureFlags["flag.exp.show_address_fields_on_register"],
  );
  const countries = useSelector((state) => state.misc.countries);

  useEffect(() => {
    dispatch(getCountriesThunk());
  }, []);

  const handleRegisterCustomer = async ({ name, taxId, countryId, billingAddress, isAgency }) => {
    setRegisterCompanyStatus("loading");
    const res = await api.registerCompany({
      name,
      taxId,
      countryId,
      billingAddress,
      isAgency,
    });
    if (res.status !== 200) {
      setRegisterCompanyStatus("error");
      return;
    }
    setRegisterCompanyStatus("success");
    setCompany(res.data?.payload);
    dispatch(getCompaniesThunk({ page: 1 }));
  };

  return (
    <NewCustomerModal
      open={open}
      onClose={onClose}
      countries={countries}
      company={company}
      handleRegisterCustomer={handleRegisterCustomer}
      registerCompanyStatus={registerCompanyStatus}
      showBillingAddress={showBillingAddress}
    />
  );
};

export default NewCustomerDialog;
