import React from "react";

// // component
import OwnerDashboard from "/src/containers/owner-dashboard";

import config from "/src/config.js";
import SEO from "/src/components/seo";
import BacAdminThemeProvider from "/src/providers/bac-admin-theme-provider";
import { useDispatch } from "react-redux";
import { setCurrentAsset } from "../../redux/actions/misc-actions";
import SetupContainer from "../../setup-container";

const AdminPage = () => {
  const dispatch = useDispatch();
  dispatch(setCurrentAsset());

  return (
    <>
      <SetupContainer />
      <SEO
        title={`${config.CLIENT_NAME} - Admin`}
        description={config.CLIENT_DESCRIPTION}
        image={encodeURI(config.LOGO_DARK) || ""}
      />
      <BacAdminThemeProvider>
        <OwnerDashboard />
      </BacAdminThemeProvider>
    </>
  );
};

export default AdminPage;
