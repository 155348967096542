import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InvoicingReportComponent from "../../components/invoicing-report";
import { getInvoicingReportThunk } from "../../redux/actions/invoicing-report-actions";
import { downloadBlobFiles, filterColumnsDisplayTableToString } from "../../utils";
import { downloadInvoicesByUserData, downloadInvoicesData } from "../../api/invoicing-report";

const InvoicingReportContainer = () => {
  const dispatch = useDispatch();

  const invoices = useSelector((state) => state.invoicingReport.invoices.data);
  const pagination = useSelector((state) => state.invoicingReport.invoices.pagination);
  const flagInvoiceTransactions = useSelector((state) => state.misc.featureFlags["flag.dev.invoice_transactions"]);
  const flagShowMriFields = useSelector((state) => state.misc.featureFlags["flag.exp.invoice_mri_fileds"]);
  const filters = useSelector((state) => state.invoicingReport.filters);
  const status = useSelector((state) => state.invoicingReport.status);

  const [sort, setSort] = useState({});
  const [columns, setColumns] = useState("");

  useEffect(() => {
    if (!filters.start) {
      return;
    }
    dispatch(
      getInvoicingReportThunk({
        page: 1,
        sort,
        filters,
      }),
    );
  }, [sort, filters]);

  const handleTableChange = (action, tableState) => {
    const columnsDisplay = filterColumnsDisplayTableToString(tableState);
    setColumns(columnsDisplay);
    switch (action) {
      case "sort":
        localStorage.setItem("invoicesColumnsSort", JSON.stringify(tableState.sortOrder));
        setSort(tableState.sortOrder);
        break;
    }
  };

  const downloadInvocingData = async (format) => {
    const download = flagInvoiceTransactions ? downloadInvoicesByUserData : downloadInvoicesData;
    const res = await download({
      exportFormat: format,
      filters,
      sort,
      columns,
    });
    downloadBlobFiles(res, `Facturacion.${format}`);
  };

  const handleChangePage = (evt, page) => {
    dispatch(
      getInvoicingReportThunk({
        page,
        sort,
        filters,
      }),
    );
  };

  return (
    <InvoicingReportComponent
      invoices={invoices}
      pagination={pagination}
      onChangePage={handleChangePage}
      downloadTableData={downloadInvocingData}
      status={status}
      onTableChange={handleTableChange}
      flagInvoiceTransactions={flagInvoiceTransactions}
      flagShowMriFields={flagShowMriFields}
    />
  );
};

export default InvoicingReportContainer;
