import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StepUsesComponent from "../../components/create-booking-wizard/step-4-uses";
import api from "../../api";

function StepUsesContainer({ updateBooking, booking, onBack }) {
  const [bookingCategories, setBookingCategories] = useState([]);
  const [bookingCategoriesStatus, setBookingCategoriesStatus] = useState("initial");
  const [incomeBookingCategories, setIncomeBookingCategories] = useState([]);
  const [incomeBookingCategoriesStatus, setIncomeBookingCategoriesStatus] = useState("initial");

  const showCategoryIncome = booking.asset.module === "mri" || booking.asset.module === "mri_direct";

  const getBookingCategories = async () => {
    setBookingCategoriesStatus("loading");
    const res = await api.getBookingCategories();
    if (res.status === 200) {
      setBookingCategoriesStatus("success");
      setBookingCategories(res.data.payload.data);
    } else {
      setBookingCategoriesStatus("error");
    }
  };

  const getIncomeBookingCategories = async () => {
    setIncomeBookingCategoriesStatus("loading");
    const res = await api.getIncomeBookingCategories();
    if (res.status === 200) {
      setIncomeBookingCategoriesStatus("success");
      setIncomeBookingCategories(res.data.payload);
    } else {
      setIncomeBookingCategoriesStatus("error");
    }
  };

  const handleSubmit = ({ bookingCategory, incomeBookingCategory }) =>
    updateBooking({
      ...booking,
      bookingCategory,
      ...(showCategoryIncome && { category_income: incomeBookingCategory }),
    });

  useEffect(() => {
    getBookingCategories();
  }, []);

  useEffect(() => {
    if (showCategoryIncome) {
      getIncomeBookingCategories();
    }
  }, [showCategoryIncome]);

  return (
    <StepUsesComponent
      showCategoryIncome={showCategoryIncome}
      onBack={onBack}
      onSubmit={handleSubmit}
      bookingCategories={bookingCategories}
      bookingCategoriesStatus={bookingCategoriesStatus}
      incomeBookingCategories={incomeBookingCategories}
      incomeBookingCategoriesStatus={incomeBookingCategoriesStatus}
    />
  );
}

export default StepUsesContainer;
